<template>
  <!-- 转换页解释组件 -->
  <div class="explain">
    <h2 class="title">{{ content.title }}</h2>
    <p class="des">{{ content.des }}</p>
    <div class="explain-list">
      <template v-for="(item, idx) in content.list">
        <div :key="idx" class="explain-item">
          <div class="item-left">
            <h3 class="mini-title">{{ item.title }}</h3>
            <p class="des">{{ item.des }}</p>
          </div>
          <img :src="$getFile(item.img)" v-if="item.img" alt="">
          <ImageComparison :dataList="item.dataList" v-if="item.dataList" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Explain',
  props: {
    content: {
      type: Object,
      required: true,
      default: () => {}
    },
  },
  data() {
    return {}
  }
}
</script>

<style scoped lang="scss">
  @media (min-width: 980px) {
    .explain {
      width: 1180px;
      margin: 0 auto;
      padding-top: 120px;
      padding-bottom: 120px;
      text-align: center;
      .title {
        font-size: 36px;
        font-family: Poppins-Bold, Poppins;
        font-weight: bold;
        color: #05021E;
        line-height: 52px;
        margin-bottom: 20px;
      }
      .des {
        font-size: 16px;
        font-family: Poppins-Regular, Poppins;
        font-weight: 400;
        color: #05021E;
            padding-left: 10px;
        line-height: 24px;
      }
      .explain-list {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 80px;
        margin-top: 60px;
        text-align: left;
        .explain-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: #dedede;
          border-radius: 10px;
          padding: 0;
          &:nth-child(2n+2) {
            flex-direction: row-reverse;
          }
          .item-left {
            width: 640px;
            margin: 0 60px;
            .mini-title {
              font-size: 24px;
              font-family: Poppins-Bold, Poppins;
              font-weight: bold;
              color: #05021E;
              line-height: 32px;
              margin-bottom: 10px;
            padding-left: 10px;
            }
          }
          img {
            width: 480px;
            height: 320px;
          }
        }
      }
      ::v-deep .ai-collection{
      .example-pic,.result-pic,.ori-pic-wrap,.ori-pic,.move-block,.col-line{
        height: 310px;
      }
      }
    }
  }

  @media (max-width: 980px) {
    .explain {
      width: 6.86rem;
      margin: 0 auto;
      padding-top: 1rem;
      padding-bottom: 1rem;
      text-align: center;
      .title {
        font-size: 0.48rem;
        font-family: Poppins, Poppins;
        color: #05021E;
        line-height: 0.72rem;
        margin-bottom: 0.14rem;
      }
      .des {
        font-size: 0.28rem;
        font-family: Poppins-Regular, Poppins;
        font-weight: 400;
        color: #05021E;
        line-height: 0.43rem;
      }
      .explain-list {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 0.8rem;
        margin-top: 0.8rem;
        text-align: left;
        .explain-item {
          display: flex;
          flex-direction: column-reverse;
          background: #dedede;
          border-radius: 10px;
          padding: 0 10px;
          .item-left {
            .mini-title {
              font-size: 0.32rem;
              font-family: Poppins-Bold, Poppins;
              color: #05021E;
              line-height: 0.48rem;
              margin-bottom: 0.16rem;
            }
          }
          img {
            width: 6.68rem;
            height: 4.57rem;
            display: block;
            margin: 0 auto 0.41rem auto;
          }
        }
      }
    }
  }
</style>