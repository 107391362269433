<template>
  <!-- 转换页为什么要转换问题展示组件 -->
  <div class="more-container" :style="content.style || {}">
    <div class="more">
      <h2>{{ content.title }}</h2>
      <p class="des">{{ content.des }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GetMore',
  props: {
    content: {
      type: Object,
      required: true,
      default: () => {}
    }
  }
}
</script>

<style scoped lang="scss">
  @media (width > 981px) {
    .more-container {
      padding: 100px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #000029;
      position: relative;
      background: var(--theme-home-primary);
      &:before {
        content: '';
        position: absolute;
        width: 229px;
        height: 135px;
        background: url("../assets/images/background/more-left.webp");
        background-size: 100% 100%;
        top: 0;
        left: 90px;
      }
      &:after {
        content: '';
        position: absolute;
        width: 60px;
        height: 35px;
        background: url("../assets/images/background/more-center.webp");
        background-size: 100% 100%;
        top: 0;
        left: 40%;
      }
      .more {
        width: 1000px;
        z-index: 1;
        &:before {
          content: '';
          position: absolute;
          width: 340px;
          height: 215px;
          background: url("../assets/images/background/more-right.webp");
          background-size: 100% 100%;
          bottom: 0;
          right: 90px;
        }
        h2 {
          font-size: 36px;
          font-family: Poppins-Bold, Poppins;
          font-weight: bold;
          color: #000029;
          line-height: 52px;
          margin-bottom: 20px;
        }
        .des {
          font-size: 16px;
          font-family: Poppins-Medium, Poppins;
          font-weight: 500;
          color: #000029;
          line-height: 26px;
        }
      }
    }
  }
  @media (width < 980px) {
    .more-container {
      padding: 1rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #000029;
      position: relative;
      background: var(--theme-home-primary);
      overflow: hidden;
      &:before {
        content: '';
        position: absolute;
        width: 2.08rem;
        height: 2.08rem;
        background: url("../assets/images/background/more-circle.png");
        background-size: 100% 100%;
        top: -1.04rem;
        left: 0.18rem;
      }
      &:after {
        content: '';
        position: absolute;
        width: 1.04rem;
        height: 1.04rem;
        background: url("../assets/images/background/more-circle.png");
        background-size: 100% 100%;
        top: 0.52rem;
        right: 0.29rem;
      }
      .more {
        width: 6.83rem;
        z-index: 1;
        &:before {
          content: '';
          position: absolute;
          width: 3rem;
          height: 3rem;
          background: url("../assets/images/background/more-circle.png");
          background-size: 100% 100%;
          bottom: -1.1rem;
          right: -0.6rem;
        }
        h2 {
          font-size: 0.48rem;
          font-family: Poppins-Bold, Poppins;
          color: #000029;
          line-height: 0.52rem;
          margin-bottom: 0.48rem;
        }
        .des {
          font-size: 0.28rem;
          font-family: Poppins-Regular, Poppins;
          font-weight: 400;
          color: #000029;
          line-height: 0.4rem;
        }
      }
    }
  }
</style>
