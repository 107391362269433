import Vue from 'vue'
import App from './App.vue'
Vue.config.productionTip = false
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import router from './router'

Vue.use(VueAwesomeSwiper)
Vue.prototype.$getFile = (name) => {
  return name && require('./assets/images/' + name)
}
// 引入require.context函数
const requireComponent = require.context('./components', false, /\.vue$/);
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName);
  const componentName = fileName.replace(/^\.\//, '').replace(/\.\w+$/, '');
  console.log(componentName, ">>>")
  Vue.component(componentName, componentConfig.default || componentConfig);
});

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
