<template>
  <!-- 照片对比动画组件 -->
  <div class="ai-collection">
    <div
      :id="`ai-collection-example`"
      class="example-pic"
      @mousedown="mousedown($event)"
      @mouseup="mouseup($event)"
      @mousemove="mousemove($event)"
      @touchstart="touchstart($event)"
      @touchend="touchend($event)"
      @touchmove="touchmove($event)"
    >
      <div class="ori-pic-wrap" :style="{ width: posX + '%' }">
        <img
          :src="$getFile(dataList[currentIndex].originImg)"
          alt="ori-pic"
          class="ori-pic"
          @load="imgLoad"
        />
      </div>
      <img
        :src="$getFile(dataList[currentIndex].resultImg)"
        :style="{ transform: `scale(${imgScal})` }"
        alt="result-pic"
        class="result-pic"
      />
      <div class="move-block" :style="{ left: posX + '%' }">
        <img
          src="../assets/images/compliance-testing/move-btn@2x.png"
          alt="move-btn"
          class="move-btn"
          draggable="false"
        />
        <div class="col-line" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageComparison',
  props: {
    dataList: {
      type: Array,
      default: () => {
        return []
      },
    },
    cycleTime: {
      type: Number,
      default: 3000,
    },
    loop: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mouseIsEnter: false,
      posX: 0,
      posY: 0,
      Animation: false,
      currentIndex: 0,
      imgScal: 1,
    }
  },

  created() {
    if (!this.loop) this.posX = 50
  },

  methods: {
    imgLoad(e) {
      clearInterval(this.Interval)
      this.imgScal = 1
      if (this.loop) {
        this.posX = 0
        this.AnimationLoop()
      }
    },
    AnimationLoop() {
      this.timer = setTimeout(() => {
        this.currentIndex =
          this.currentIndex < this.dataList.length - 1
            ? this.currentIndex + 1
            : 0
        this.imgScal = 2
      }, this.cycleTime)
      this.Interval = setInterval(() => {
        if (this.posX < 100) {
          this.posX += 1
        } else {
          this.posX = 0
          clearInterval(this.Interval)
        }
      }, this.cycleTime / 100)
    },

    calc(event) {
      const _dom = document.querySelector('#ai-collection-example')
      const { left, top, width, height } = _dom.getBoundingClientRect()
      const { clientX, clientY } = event

      const leftPer = parseFloat(((clientX - left) / width) * 100).toFixed(2)
      const rightPer = parseFloat(((clientY - top) / height) * 100).toFixed(2)

      this.posX = leftPer
      this.posY = rightPer
    },

    mousedown(event) {
      this.calc(event)

      this.mouseIsEnter = true
    },

    mouseup(event) {
      this.mouseIsEnter = false
    },

    mousemove(event) {
      if (!this.mouseIsEnter) {
        return
      }
      this.calc(event)
    },

    touchstart(event) {
      this.calc(event.targetTouches[0])

      this.mouseIsEnter = true
    },

    touchend(event) {
      this.mouseIsEnter = false
    },

    touchmove(event) {
      if (!this.mouseIsEnter) {
        return
      }
      this.calc(event.targetTouches[0])
    },
  },
}
</script>

<style lang="scss" scoped>
@media (min-width: 980px) {
  .ai-collection {
    position: relative;

    .example-pic {
      flex: 0 0 385px;
      position: relative;
      width: 385px;
      height: 312px;
      background: #d8d8d8;
      border-radius: 16px;
      overflow: hidden;
      cursor: pointer;

      .result-pic {
        position: absolute;
        top: 0;
        left: 0;
        width: 385px;
        height: 312px;
        transition: transform 300ms ease;
      }

      .ori-pic-wrap {
        position: absolute;
        top: 0;
        left: 0;
        width: 385px;
        height: 312px;
        overflow: hidden;
        z-index: 10;

        .ori-pic {
          width: 385px;
          height: 312px;
        }

        .icon-mistake {
          position: absolute;
          top: 20px;
          left: 20px;
          width: 48px;
          height: 48px;
          z-index: 60;
        }
      }

      .move-block {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        height: 312px;
        z-index: 60;
        user-select: none;

        &:hover {
          cursor: ew-resize;
        }

        .move-btn {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 30px;
          height: 30px;
        }
        .col-line {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 2px;
          height: 312px;
          background: #ffffff;
        }
      }
    }
  }
}

@media (max-width: 980px) {
  .ai-collection {
    position: relative;
    .example-pic {
      flex: 0 0 7.5rem;
      position: relative;
      height: 7.8rem;
      background: #d8d8d8;
      // border-radius: 16px;
      overflow: hidden;

      .result-pic {
        position: absolute;
        top: 0;
        left: 0;
        width: 7.5rem;
        height: 7.8rem;
        transition: transform 300ms ease;
      }

      .ori-pic-wrap {
        position: absolute;
        top: 0;
        left: 0;
        width: 7.5rem;
        height: 7.8rem;
        overflow: hidden;
        z-index: 10;

        .ori-pic {
          width: 7.5rem;
          height: 7.8rem;
        }

        .icon-mistake {
          position: absolute;
          top: 20px;
          left: 20px;
          width: 48px;
          height: 48px;
          z-index: 60;
        }
      }

      .move-block {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        height: 7.8rem;
        z-index: 12;
        user-select: none;

        &:hover {
          cursor: ew-resize;
        }

        .move-btn {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 30px;
          height: 30px;
        }

        .move-help {
          position: absolute;
          top: 55%;
          right: 50%;
          width: 0.62rem;
          height: 0.88rem;
          animation: mymove 3s linear 0s infinite;
        }
        @keyframes mymove {
          0% {
            opacity: 1;
            right: 50%;
          }
          50% {
            opacity: 0.5;
            right: -75%;
          }
          100% {
            opacity: 1;
            right: 50%;
          }
        }
        .col-line {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 2px;
          height: 7.8rem;
          background: #ffffff;
        }
      }
    }
  }
}
</style>
